import LanguageUtil from "@/commons/LanguageUtil";
import { defineComponent, reactive } from "vue";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import DIContainer from "@/core/DIContainer";
import OrganizationProjectInteractor from "@/domain/usecases/OrganizationProjectInteractor";
import Constant from "@/commons/Constant";
import Input from "@/presentation/components/input/Input.vue";
import MyConfig from '@/_config';
export default defineComponent({
    name: "SMSPopup",
    components: {
        ErrorMessage: ErrorMessage,
        Input: Input
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            country_code: [
                { country_code: "AF", name_ja: "アフガニスタン", name_en: "Afghanistan", phone_code: "93" },
                { country_code: "AO", name_ja: "アンゴラ", name_en: "Angola", phone_code: "244" },
                { country_code: "AL", name_ja: "アルバニア", name_en: "Albania", phone_code: "355" },
                { country_code: "AD", name_ja: "アンドラ", name_en: "Andorra", phone_code: "376" },
                { country_code: "AE", name_ja: "アラブ首長国連邦", name_en: "United Arab Emirates", phone_code: "971" },
                { country_code: "AR", name_ja: "アルゼンチン", name_en: "Argentina", phone_code: "54" },
                { country_code: "AM", name_ja: "アルメニア", name_en: "Armenia", phone_code: "374" },
                { country_code: "AG", name_ja: "アンティグア・バーブーダ", name_en: "Antigua and Barbuda", phone_code: "-267" },
                { country_code: "AU", name_ja: "オーストラリア", name_en: "Australia", phone_code: "61" },
                { country_code: "AT", name_ja: "オーストリア", name_en: "Austria", phone_code: "43" },
                { country_code: "AZ", name_ja: "アゼルバイジャン", name_en: "Azerbaijan", phone_code: "994" },
                { country_code: "BI", name_ja: "ブルンジ", name_en: "Burundi", phone_code: "257" },
                { country_code: "BE", name_ja: "ベルギー", name_en: "Belgium", phone_code: "32" },
                { country_code: "BJ", name_ja: "ベナン", name_en: "Benin", phone_code: "229" },
                { country_code: "BF", name_ja: "ブルキナファソ", name_en: "Burkina Faso", phone_code: "226" },
                { country_code: "BD", name_ja: "バングラデシュ", name_en: "Bangladesh", phone_code: "880" },
                { country_code: "BG", name_ja: "ブルガリア", name_en: "Bulgaria", phone_code: "359" },
                { country_code: "BH", name_ja: "バーレーン", name_en: "Bahrain", phone_code: "973" },
                { country_code: "BS", name_ja: "バハマ", name_en: "Bahamas", phone_code: "-241" },
                { country_code: "BA", name_ja: "ボスニア・ヘルツェゴビナ", name_en: "Bosnia and Herzegovina", phone_code: "387" },
                { country_code: "BY", name_ja: "ベラルーシ", name_en: "Belarus", phone_code: "375" },
                { country_code: "BZ", name_ja: "ベリーズ", name_en: "Belize", phone_code: "501" },
                { country_code: "BO", name_ja: "ボリビア多民族国", name_en: "Bolivia, Plurinational State of", phone_code: "591" },
                { country_code: "BR", name_ja: "ブラジル", name_en: "Brazil", phone_code: "55" },
                { country_code: "BB", name_ja: "バルバドス", name_en: "Barbados", phone_code: "1-246" },
                { country_code: "BN", name_ja: "ブルネイ・ダルサラーム", name_en: "Brunei Darussalam", phone_code: "673" },
                { country_code: "BT", name_ja: "ブータン", name_en: "Bhutan", phone_code: "975" },
                { country_code: "BW", name_ja: "ボツワナ", name_en: "Botswana", phone_code: "267" },
                { country_code: "CF", name_ja: "中央アフリカ共和国", name_en: "Central African Republic", phone_code: "236" },
                { country_code: "CA", name_ja: "カナダ", name_en: "Canada", phone_code: "1" },
                { country_code: "CH", name_ja: "スイス", name_en: "Switzerland", phone_code: "41" },
                { country_code: "CL", name_ja: "チリ", name_en: "Chile", phone_code: "56" },
                { country_code: "CN", name_ja: "中華人民共和国", name_en: "China", phone_code: "86" },
                { country_code: "CI", name_ja: "コートジボワール", name_en: "Côte d'Ivoire", phone_code: "225" },
                { country_code: "CM", name_ja: "カメルーン", name_en: "Cameroon", phone_code: "237" },
                { country_code: "CD", name_ja: "コンゴ民主共和国", name_en: "Congo, the Democratic Republic of the", phone_code: "243" },
                { country_code: "CG", name_ja: "コンゴ共和国", name_en: "Congo", phone_code: "242" },
                { country_code: "CK", name_ja: "クック諸島", name_en: "Cook Islands", phone_code: "682" },
                { country_code: "CO", name_ja: "コロンビア", name_en: "Colombia", phone_code: "57" },
                { country_code: "KM", name_ja: "コモロ", name_en: "Comoros", phone_code: "269" },
                { country_code: "CV", name_ja: "カーボベルデ", name_en: "Cape Verde", phone_code: "238" },
                { country_code: "CR", name_ja: "コスタリカ", name_en: "Costa Rica", phone_code: "506" },
                { country_code: "CU", name_ja: "キューバ", name_en: "Cuba", phone_code: "53" },
                { country_code: "CY", name_ja: "キプロス", name_en: "Cyprus", phone_code: "357" },
                { country_code: "CZ", name_ja: "チェコ", name_en: "Czechia", phone_code: "420" },
                { country_code: "DE", name_ja: "ドイツ", name_en: "Germany", phone_code: "49" },
                { country_code: "DJ", name_ja: "ジブチ", name_en: "Djibouti", phone_code: "253" },
                { country_code: "DM", name_ja: "ドミニカ国", name_en: "Dominica", phone_code: "-766" },
                { country_code: "DK", name_ja: "デンマーク", name_en: "Denmark", phone_code: "45" },
                { country_code: "DO", name_ja: "ドミニカ共和国", name_en: "Dominican Republic", phone_code: "-808" },
                { country_code: "DZ", name_ja: "アルジェリア", name_en: "Algeria", phone_code: "213" },
                { country_code: "ED", name_ja: "エクアドル", name_en: "Ecuador", phone_code: "593" },
                { country_code: "EG", name_ja: "エジプト", name_en: "Egypt", phone_code: "20" },
                { country_code: "ER", name_ja: "エリトリア", name_en: "Eritrea", phone_code: "291" },
                { country_code: "ES", name_ja: "スペイン", name_en: "Spain", phone_code: "34" },
                { country_code: "EE", name_ja: "エストニア", name_en: "Estonia", phone_code: "372" },
                { country_code: "ET", name_ja: "エチオピア", name_en: "Ethiopia", phone_code: "251" },
                { country_code: "FI", name_ja: "フィンランド", name_en: "Finland", phone_code: "358" },
                { country_code: "FJ", name_ja: "フィジー", name_en: "Fiji", phone_code: "679" },
                { country_code: "FR", name_ja: "フランス", name_en: "France", phone_code: "33" },
                { country_code: "FM", name_ja: "ミクロネシア連邦", name_en: "Micronesia, Federated States of", phone_code: "691" },
                { country_code: "GA", name_ja: "ガボン", name_en: "Gabon", phone_code: "241" },
                { country_code: "GB", name_ja: "イギリス", name_en: "United Kingdom", phone_code: "44" },
                { country_code: "GE", name_ja: "ジョージア", name_en: "Georgia", phone_code: "995" },
                { country_code: "GH", name_ja: "ガーナ", name_en: "Ghana", phone_code: "233" },
                { country_code: "GN", name_ja: "ギニア", name_en: "Guinea", phone_code: "224" },
                { country_code: "GM", name_ja: "ガンビア", name_en: "Gambia", phone_code: "220" },
                { country_code: "GW", name_ja: "ギニアビサウ", name_en: "Guinea-Bissau", phone_code: "245" },
                { country_code: "GQ", name_ja: "赤道ギニア", name_en: "Equatorial Guinea", phone_code: "240" },
                { country_code: "GR", name_ja: "ギリシャ", name_en: "Greece", phone_code: "30" },
                { country_code: "GD", name_ja: "グレナダ", name_en: "Grenada", phone_code: "-472" },
                { country_code: "GT", name_ja: "グアテマラ", name_en: "Guatemala", phone_code: "502" },
                { country_code: "GY", name_ja: "ガイアナ", name_en: "Guyana", phone_code: "592" },
                { country_code: "HN", name_ja: "ホンジュラス", name_en: "Honduras", phone_code: "504" },
                { country_code: "HR", name_ja: "クロアチア", name_en: "Croatia", phone_code: "385" },
                { country_code: "HT", name_ja: "ハイチ", name_en: "Haiti", phone_code: "509" },
                { country_code: "HU", name_ja: "ハンガリー", name_en: "Hungary", phone_code: "36" },
                { country_code: "ID", name_ja: "インドネシア", name_en: "Indonesia", phone_code: "62" },
                { country_code: "IN", name_ja: "インド", name_en: "India", phone_code: "91" },
                { country_code: "IE", name_ja: "アイルランド", name_en: "Ireland", phone_code: "353" },
                { country_code: "IR", name_ja: "イラン・イスラム共和国", name_en: "Iran, Islamic Republic of", phone_code: "98" },
                { country_code: "IQ", name_ja: "イラク", name_en: "Iraq", phone_code: "964" },
                { country_code: "IS", name_ja: "アイスランド", name_en: "Iceland", phone_code: "354" },
                { country_code: "IL", name_ja: "イスラエル", name_en: "Israel", phone_code: "972" },
                { country_code: "IT", name_ja: "イタリア", name_en: "Italy", phone_code: "39" },
                { country_code: "JM", name_ja: "ジャマイカ", name_en: "Jamaica", phone_code: "-975" },
                { country_code: "JO", name_ja: "ヨルダン", name_en: "Jordan", phone_code: "962" },
                { country_code: "JP", name_ja: "日本", name_en: "Japan", phone_code: "81" },
                { country_code: "KZ", name_ja: "カザフスタン", name_en: "Kazakhstan", phone_code: "7" },
                { country_code: "KE", name_ja: "ケニア", name_en: "Kenya", phone_code: "254" },
                { country_code: "KG", name_ja: "キルギス", name_en: "Kyrgyzstan", phone_code: "996" },
                { country_code: "KH", name_ja: "カンボジア", name_en: "Cambodia", phone_code: "855" },
                { country_code: "KI", name_ja: "キリバス", name_en: "Kiribati", phone_code: "686" },
                { country_code: "KN", name_ja: "セントクリストファー・ネイビス", name_en: "Saint Kitts and Nevis", phone_code: "-868" },
                { country_code: "KR", name_ja: "大韓民国", name_en: "Korea, Republic of", phone_code: "82" },
                { country_code: "XK", name_ja: "コソボ", name_en: "Kosovo", phone_code: "383" },
                { country_code: "KW", name_ja: "クウェート", name_en: "Kuwait", phone_code: "965" },
                { country_code: "LA", name_ja: "ラオス人民民主共和国", name_en: "Lao People's Democratic Republic", phone_code: "856" },
                { country_code: "LB", name_ja: "レバノン", name_en: "Lebanon", phone_code: "961" },
                { country_code: "LR", name_ja: "リベリア", name_en: "Liberia", phone_code: "231" },
                { country_code: "LY", name_ja: "リビア", name_en: "Libya", phone_code: "218" },
                { country_code: "LC", name_ja: "セントルシア", name_en: "Saint Lucia", phone_code: "-757" },
                { country_code: "LI", name_ja: "リヒテンシュタイン", name_en: "Liechtenstein", phone_code: "423" },
                { country_code: "LK", name_ja: "スリランカ", name_en: "Sri Lanka", phone_code: "94" },
                { country_code: "LS", name_ja: "レソト", name_en: "Lesotho", phone_code: "266" },
                { country_code: "LT", name_ja: "リトアニア", name_en: "Lithuania", phone_code: "370" },
                { country_code: "LU", name_ja: "ルクセンブルク", name_en: "Luxembourg", phone_code: "352" },
                { country_code: "LV", name_ja: "ラトビア", name_en: "Latvia", phone_code: "371" },
                { country_code: "MA", name_ja: "モロッコ", name_en: "Morocco", phone_code: "212" },
                { country_code: "MC", name_ja: "モナコ", name_en: "Monaco", phone_code: "377" },
                { country_code: "MD", name_ja: "モルドバ共和国", name_en: "Moldova, Republic of", phone_code: "373" },
                { country_code: "MG", name_ja: "マダガスカル", name_en: "Madagascar", phone_code: "261" },
                { country_code: "MV", name_ja: "モルディブ", name_en: "Maldives", phone_code: "960" },
                { country_code: "MX", name_ja: "メキシコ", name_en: "Mexico", phone_code: "52" },
                { country_code: "MH", name_ja: "マーシャル諸島", name_en: "Marshall Islands", phone_code: "692" },
                { country_code: "MK", name_ja: "北マケドニア", name_en: "North Macedonia", phone_code: "389" },
                { country_code: "ML", name_ja: "マリ", name_en: "Mali", phone_code: "223" },
                { country_code: "MT", name_ja: "マルタ", name_en: "Malta", phone_code: "356" },
                { country_code: "MM", name_ja: "ミャンマー", name_en: "Myanmar", phone_code: "95" },
                { country_code: "ME", name_ja: "モンテネグロ", name_en: "Montenegro", phone_code: "382" },
                { country_code: "MN", name_ja: "モンゴル", name_en: "Mongolia", phone_code: "976" },
                { country_code: "MZ", name_ja: "モザンビーク", name_en: "Mozambique", phone_code: "258" },
                { country_code: "MR", name_ja: "モーリタニア", name_en: "Mauritania", phone_code: "222" },
                { country_code: "MU", name_ja: "モーリシャス", name_en: "Mauritius", phone_code: "230" },
                { country_code: "MW", name_ja: "マラウイ", name_en: "Malawi", phone_code: "265" },
                { country_code: "MY", name_ja: "マレーシア", name_en: "Malaysia", phone_code: "60" },
                { country_code: "NA", name_ja: "ナミビア", name_en: "Namibia", phone_code: "264" },
                { country_code: "NE", name_ja: "ニジェール", name_en: "Niger", phone_code: "227" },
                { country_code: "NG", name_ja: "ナイジェリア", name_en: "Nigeria", phone_code: "234" },
                { country_code: "NI", name_ja: "ニカラグア", name_en: "Nicaragua", phone_code: "505" },
                { country_code: "NU", name_ja: "ニウエ", name_en: "Niue", phone_code: "683" },
                { country_code: "NL", name_ja: "オランダ", name_en: "Netherlands", phone_code: "31" },
                { country_code: "NO", name_ja: "ノルウェー", name_en: "Norway", phone_code: "47" },
                { country_code: "NP", name_ja: "ネパール", name_en: "Nepal", phone_code: "977" },
                { country_code: "NR", name_ja: "ナウル", name_en: "Nauru", phone_code: "674" },
                { country_code: "NZ", name_ja: "ニュージーランド", name_en: "New Zealand", phone_code: "64" },
                { country_code: "OM", name_ja: "オマーン", name_en: "Oman", phone_code: "968" },
                { country_code: "PK", name_ja: "パキスタン", name_en: "Pakistan", phone_code: "92" },
                { country_code: "PA", name_ja: "パナマ", name_en: "Panama", phone_code: "507" },
                { country_code: "PE", name_ja: "ペルー", name_en: "Peru", phone_code: "51" },
                { country_code: "PH", name_ja: "フィリピン", name_en: "Philippines", phone_code: "63" },
                { country_code: "PW", name_ja: "パラオ", name_en: "Palau", phone_code: "680" },
                { country_code: "PG", name_ja: "パプアニューギニア", name_en: "Papua New Guinea", phone_code: "675" },
                { country_code: "PL", name_ja: "ポーランド", name_en: "Poland", phone_code: "48" },
                { country_code: "PT", name_ja: "ポルトガル", name_en: "Portugal", phone_code: "351" },
                { country_code: "PY", name_ja: "パラグアイ", name_en: "Paraguay", phone_code: "595" },
                { country_code: "QA", name_ja: "カタール", name_en: "Qatar", phone_code: "974" },
                { country_code: "RO", name_ja: "ルーマニア", name_en: "Romania", phone_code: "40" },
                { country_code: "RU", name_ja: "ロシア連邦", name_en: "Russian Federation", phone_code: "7" },
                { country_code: "RW", name_ja: "ルワンダ", name_en: "Rwanda", phone_code: "250" },
                { country_code: "SA", name_ja: "サウジアラビア", name_en: "Saudi Arabia", phone_code: "966" },
                { country_code: "SD", name_ja: "スーダン", name_en: "Sudan", phone_code: "249" },
                { country_code: "SN", name_ja: "セネガル", name_en: "Senegal", phone_code: "221" },
                { country_code: "SG", name_ja: "シンガポール", name_en: "Singapore", phone_code: "65" },
                { country_code: "SB", name_ja: "ソロモン諸島", name_en: "Solomon Islands", phone_code: "677" },
                { country_code: "SL", name_ja: "シエラレオネ", name_en: "Sierra Leone", phone_code: "232" },
                { country_code: "SV", name_ja: "エルサルバドル", name_en: "El Salvador", phone_code: "503" },
                { country_code: "SM", name_ja: "サンマリノ", name_en: "San Marino", phone_code: "378" },
                { country_code: "SO", name_ja: "ソマリア", name_en: "Somalia", phone_code: "252" },
                { country_code: "RS", name_ja: "セルビア", name_en: "Serbia", phone_code: "381" },
                { country_code: "SS", name_ja: "南スーダン", name_en: "South Sudan", phone_code: "211" },
                { country_code: "ST", name_ja: "サントメ・プリンシペ", name_en: "Sao Tome and Principe", phone_code: "239" },
                { country_code: "SR", name_ja: "スリナム", name_en: "Suriname", phone_code: "597" },
                { country_code: "SK", name_ja: "スロバキア", name_en: "Slovakia", phone_code: "421" },
                { country_code: "SI", name_ja: "スロベニア", name_en: "Slovenia", phone_code: "386" },
                { country_code: "SE", name_ja: "スウェーデン", name_en: "Sweden", phone_code: "46" },
                { country_code: "SZ", name_ja: "エスワティニ", name_en: "Eswatini", phone_code: "268" },
                { country_code: "SC", name_ja: "セーシェル", name_en: "Seychelles", phone_code: "248" },
                { country_code: "SY", name_ja: "シリア・アラブ共和国", name_en: "Syrian Arab Republic", phone_code: "963" },
                { country_code: "TD", name_ja: "チャド", name_en: "Chad", phone_code: "235" },
                { country_code: "TG", name_ja: "トーゴ", name_en: "Togo", phone_code: "228" },
                { country_code: "TH", name_ja: "タイ", name_en: "Thailand", phone_code: "66" },
                { country_code: "TJ", name_ja: "タジキスタン", name_en: "Tajikistan", phone_code: "992" },
                { country_code: "TM", name_ja: "トルクメニスタン", name_en: "Turkmenistan", phone_code: "993" },
                { country_code: "TL", name_ja: "東ティモール", name_en: "Timor-Leste", phone_code: "670" },
                { country_code: "TO", name_ja: "トンガ", name_en: "Tonga", phone_code: "676" },
                { country_code: "TT", name_ja: "トリニダード・トバゴ", name_en: "Trinidad and Tobago", phone_code: "-867" },
                { country_code: "TN", name_ja: "チュニジア", name_en: "Tunisia", phone_code: "216" },
                { country_code: "TR", name_ja: "トルコ", name_en: "Turkey", phone_code: "90" },
                { country_code: "TV", name_ja: "ツバル", name_en: "Tuvalu", phone_code: "688" },
                { country_code: "TW", name_ja: "中国台湾省（中華民国）", name_en: "Taiwan, Province of China", phone_code: "886" },
                { country_code: "TZ", name_ja: "タンザニア", name_en: "Tanzania, United Republic of", phone_code: "255" },
                { country_code: "UG", name_ja: "ウガンダ", name_en: "Uganda", phone_code: "256" },
                { country_code: "UA", name_ja: "ウクライナ", name_en: "Ukraine", phone_code: "380" },
                { country_code: "UY", name_ja: "ウルグアイ", name_en: "Uruguay", phone_code: "598" },
                { country_code: "US", name_ja: "アメリカ合衆国", name_en: "United States", phone_code: "1" },
                { country_code: "UZ", name_ja: "ウズベキスタン", name_en: "Uzbekistan", phone_code: "998" },
                { country_code: "VA", name_ja: "バチカン市国", name_en: "Holy See (Vatican City State)", phone_code: "(Holy See (+379))" },
                { country_code: "VC", name_ja: "セントビンセントおよびグレナディーン諸島", name_en: "Saint Vincent and the Grenadines", phone_code: "-783" },
                { country_code: "VE", name_ja: "ベネズエラ・ボリバル共和国", name_en: "Venezuela, Bolivarian Republic of", phone_code: "58" },
                { country_code: "VN", name_ja: "ベトナム", name_en: "Viet Nam", phone_code: "84" },
                { country_code: "VU", name_ja: "バヌアツ", name_en: "Vanuatu", phone_code: "678" },
                { country_code: "WS", name_ja: "サモア", name_en: "Samoa", phone_code: "685" },
                { country_code: "YE", name_ja: "イエメン", name_en: "Yemen", phone_code: "967" },
                { country_code: "ZA", name_ja: "南アフリカ", name_en: "South Africa", phone_code: "27" },
                { country_code: "ZM", name_ja: "ザンビア", name_en: "Zambia", phone_code: "260" },
                { country_code: "ZW", name_ja: "ジンバブエ", name_en: "Zimbabwe", phone_code: "263" },
            ],
            isSubmitting: false,
            errorTelNumber: false,
            contactLink: MyConfig.contactLink
        });
        var organizationProjectInteractor = DIContainer.instance.get(OrganizationProjectInteractor);
        var unsubscribeStore;
        var _b = useForm({
            initialValues: {
                country_code: "JP",
                tel: ""
            }
        }), handleSubmit = _b.handleSubmit, values = _b.values, setValues = _b.setValues, errors = _b.errors, isSubmitting = _b.isSubmitting;
        var country_code = useField("country_code", yup
            .string()
            .nullable()
            .transform(function (value, originalValue) {
            return originalValue.trim() === "" ? "" : value;
        })).value;
        var tel = useField("tel", yup
            .string()
            .required(state.aLang.validation_required)
            .matches(Constant.PHONE_PATTERN, state.aLang.validation_format_phone_number)
            .transform(function (value, originalValue) {
            return originalValue.trim() === "" ? "" : value;
        })).value;
        var onSubmit = handleSubmit(function () {
            state.isSubmitting = true;
            organizationProjectInteractor.requestSMS(values)
                .then(function (result) {
                state.isSubmitting = false;
                emit('close');
                emit('open-otp');
            })
                .catch(function (error) {
                state.errorTelNumber = true;
                state.isSubmitting = false;
            });
        });
        return {
            state: state,
            errors: errors,
            values: values,
            onSubmit: onSubmit
        };
    }
});
