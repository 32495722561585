<template id="modal-template">
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <form
                        class="horizontal"
                        @submit="onSubmit"
                    >
                        <div class="modal-body">
                            <slot name="body">
                                <div class="otp_phone">
                                    <div class="otp_phone__title">
                                        <p>
                                            認証コードをショートメッセージで送信しました。
                                        </p>
                                        <p>６０分以内に受信した認証コード（６桁の数字）をご入力ください。</p>
                                    </div>
                                    <div class="otp_phone__content">
                                        <div class="col-3"></div>
                                        <div class="col-6">
                                            <div class="form-ctrl">
                                                <label>  </label>
                                                <Input
                                                    v-model="values.auth_code"
                                                    type="text"
                                                    id="auth_code"
                                                    name="auth_code"
                                                    maxlength="6"
                                                />

                                            </div>
                                            <div
                                                class="error"
                                                v-if="errors.auth_code || state.errorMessage"
                                            >
                                                <p v-if="errors.auth_code">
                                                    認証コードが一致しません。再入
                                                    力してください。
                                                </p>
                                                <p v-if="state.errorMessage">
                                                    {{ state.errorMessage }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="otp_phone__note">
                                        <p>
                                            ＊ 認証コードの入力を複数回間違えると同日中のSMS認証が行えなくなりますので、ご注意ください。
                                        </p>
                                    </div>
                                </div>
                            </slot>
                        </div>

                        <div class="modal-footer">
                            <slot name="footer">
                                <div class="btn_group">
                                    <button
                                        @click="$emit('close')"
                                        type="button"
                                        class="btn-lg btn-light-gray shadow"
                                    >
                                        あとで実施
                                    </button>
                                    <button
                                        :disabled="state.isSubmitting"
                                        :class="{
                                            submitting: state.isSubmitting
                                        }"
                                        type="submit"
                                        class="btn-blue btn-lg sweep-to-top"
                                    >
                                        <i
                                            v-if="state.isSubmitting"
                                            class="fas fa-circle-notch fa-spin"
                                        ></i>
                                        認証
                                    </button>
                                </div>
                            </slot>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts" src="@/presentation/components/modals/otp/Otp.ts" />
<link type="scss" scoped src="@/presentation/components/modals/otp/Otp.scss" />
