<template id="modal-template">
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <form
                        class="horizontal"
                        @submit="onSubmit"
                    >
                        <div class="modal-body">
                            <slot name="body">
                                <div class="sms_phone">
                                    <div class="sms_phone__title">
                                        <p class="sms_phone__title-main">
                                            SMS認証
                                        </p>
                                        <p>
                                            プロジェクトへの応募や、あなたのサービス登録を行う際に
                                        </p>
                                        <p>SMSの認証が必要となります。</p>
                                    </div>
                                    <div class="sms_phone__content">
                                        <div class="col-3 country_code">
                                            <div class="form-ctrl">
                                                <label> 国番号 </label>
                                                <select
                                                    v-model="values.country_code"
                                                >
                                                    <option
                                                        :value="country_code.country_code"
                                                        v-for="country_code in state.country_code"
                                                        :key="country_code.country_code"
                                                    >
                                                        {{ country_code.name_ja }} (+ {{ country_code.phone_code }} )
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-ctrl">
                                                <label> 電話番号 </label>
                                                <Input
                                                    v-model="values.tel"
                                                    type="tel"
                                                    id="tel"
                                                    name="tel"
                                                    maxlength="11"
                                                />

                                            </div>
                                            <ErrorMessage
                                                v-if="errors.tel"
                                                :message="errors.tel"
                                            />
                                            <div
                                                class="error"
                                                v-if="state.errorTelNumber"
                                            >
                                                <p>
                                                    指定した電話番号は他のアカウントにて認証済みの
                                                    ため使用できません。
                                                </p>
                                                <p>
                                                    心当たりのない方はお問い合わせください。
                                                </p>
                                                <p>
                                                    お問い合わせは
                                                    <a
                                                        class="sms_phone__content-a"
                                                        :href="state.contactLink"
                                                        target="_blank"
                                                        >こちら</a
                                                    >
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="form-ctrl">
                                                <label> &nbsp; </label>
                                                <p class="red">半角数字（ハイフンなし）</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sms_phone__note">
                                        <p>
                                            ＊SMS （ショートメッセージサービス）が利用可能な端末の電話番号をご入力ください。
                                        </p>
                                        <p>
                                            ＊ショートメッセージの受信を拒否している場合は一時的に受信を許可するよう設定の変更をしてください
                                        </p>
                                        <p>
                                            ＊ショートメッセージの通信料金はユーザ様の負担になります。
                                        </p>
                                    </div>
                                </div>
                            </slot>
                        </div>

                        <div class="modal-footer">
                            <slot name="footer">
                                <div class="btn_group">
                                    <button
                                        @click="$emit('close')"
                                        type="button"
                                        class="btn-md btn-light-gray shadow"
                                    >
                                        あとで実施
                                    </button>
                                    <button
                                        :disabled="state.isSubmitting"
                                        :class="{
                                            submitting: state.isSubmitting
                                        }"
                                        type="submit"
                                        class="btn-blue btn-md shadow"
                                    >
                                        <i
                                            v-if="state.isSubmitting"
                                            class="fas fa-circle-notch fa-spin"
                                        ></i>
                                        送信する
                                    </button>
                                </div>
                            </slot>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts" src="@/presentation/components/modals/sms/SMS.ts" />
<style lang="scss" scoped src="@/presentation/components/modals/sms/SMS.scss"/>
