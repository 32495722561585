import LanguageUtil from "@/commons/LanguageUtil";
import { defineComponent, reactive } from "vue";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import DIContainer from "@/core/DIContainer";
import OrganizationProjectInteractor from "@/domain/usecases/OrganizationProjectInteractor";
import Input from "@/presentation/components/input/Input.vue";
import { useStore } from 'vuex';
export default defineComponent({
    name: "OTPPopup",
    components: {
        ErrorMessage: ErrorMessage,
        Input: Input
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            isSubmitting: false,
            errorMessage: ""
        });
        var store = useStore();
        var OTP = /^[0-9]{6}$/;
        var organizationProjectInteractor = DIContainer.instance.get(OrganizationProjectInteractor);
        var _b = useForm({
            initialValues: {
                auth_code: null
            }
        }), handleSubmit = _b.handleSubmit, values = _b.values, setValues = _b.setValues, errors = _b.errors, isSubmitting = _b.isSubmitting;
        var auth_code = useField("auth_code", yup
            .string()
            .matches(OTP, state.aLang.otp_is_otp)
            .required(state.aLang.otp_is_otp)
            .transform(function (value, originalValue) {
            return originalValue.trim() === "" ? "" : value;
        })).value;
        var textErrorMessage = {
            "A verification code does not match. Please try again.": "認証コードが一致しません。再入力してください。"
        };
        var onSubmit = handleSubmit(function () {
            state.isSubmitting = true;
            state.errorMessage = "";
            organizationProjectInteractor.authorizeSMS(values)
                .then(function () {
                state.isSubmitting = false;
                store.commit("setHidenMenu", true);
                emit('success');
                emit('close');
            })
                .catch(function (error) {
                state.isSubmitting = false;
                if (error.validation_errors) {
                    state.errorMessage = textErrorMessage[error.validation_errors.auth_code[0]] ? textErrorMessage[error.validation_errors.auth_code[0]] : error.validation_errors.auth_code[0];
                }
            });
        });
        return {
            state: state,
            errors: errors,
            values: values,
            onSubmit: onSubmit
        };
    }
});
